import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function Searchform(props) {
  const handleChange = (event) => {
    props.setSearchAddress(event.target.value);
  };
  const clearSearch = () => {
    props.setSearchAddress("");
  };
  return (
    <Container>
      <Box component="div" sx={{ width: "md", mb: "2rem" }}>
        <Paper component="form" variant="outlined" sx={{ p: "1rem" }}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <InputBase
              sx={{ width: "50%", ml: 1, flex: 1 }}
              placeholder="Enter here your C-chain"
              inputProps={{ "aria-label": "Enter here your C-chain" }}
              value={props.search_address}
              onChange={handleChange}
            />
            {props.search_address === "" && (
              <IconButton type="button" disabled={true}>
                <SearchIcon />
              </IconButton>
            )}
            {props.search_address !== "" && (
              <IconButton type="button" onClick={clearSearch}>
                <ClearIcon />
              </IconButton>
            )}
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
}
