import React from "react";

import "./../assets/css/style.css";

import Intro from "./intro";
import SearchForm from "./searchform";
import List from "./list";

const APP_WEBSITE_META_TITLE = "Particle - Whitelisted Users - Kalao.io";
const APP_WEBSITE_META_DESCRIPTION = "Kalao x Particle - Whitelisted Users";
const APP_WEBSITE_TEXT =
  "Please see below the list of whitelisted users of the Love is in the Air (Banksy) Particle program. 100 particles of the famous painting will be put for sale. The sale date as well as the price in $KLO will be announced shortly.";

function AppParticle() {
  //Meta Title
  document.title = APP_WEBSITE_META_TITLE;
  //Meta Description
  document.getElementsByTagName("meta")["description"].content =
    APP_WEBSITE_META_DESCRIPTION;
  //State String search
  const [search_address, setSearchAddress] = React.useState("");
  return (
    <div>
      <header>
        <img
          src={require("./../assets/images/kalao-whitelist.svg").default}
          className="logo"
          alt={APP_WEBSITE_META_TITLE}
        />
      </header>
      <main>
        <Intro text={APP_WEBSITE_TEXT} />
        <SearchForm
          setSearchAddress={setSearchAddress}
          search_address={search_address}
        />
        <List search_address={search_address} />
      </main>
    </div>
  );
}

export default AppParticle;
