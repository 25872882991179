import React from "react";
import ReactDOM from "react-dom";
import AppParticle from "./AppParticle/AppParticle";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "./custom-theme";
import favicon from "./assets/images/favicon/apple-touch-icon-96.png";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Meta Default Title
document.title = "Kalao Whitelist";
//Meta Default Description
document.getElementsByTagName("meta")["description"].content = "";
//Meta Favicon
document.querySelector("link[rel='icon']").href = favicon;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <Router>
        <Routes>
          <Route exact path="/"></Route>
          <Route path="/particle" element={<AppParticle />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
