import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "1rem"
        }
      }
    },
    MuiImage: {
      styleOverrides: {
        root: {
          borderRadius: "1rem"
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0
        }
      }
    }
  }
});

export default customTheme;
