import React from "react";

import rows from "./datas.json";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "id",
    headerName: "#",
    minWidth: 50,
    flex: 0.2,
    align: "center",
    headerAlign: "center"
  },
  { field: "cchain", headerName: "C-chain", minWidth: 50, flex: 1 },
  {
    field: "nb_nfts",
    headerName: "Max mints",
    minWidth: 50,
    flex: 0.3,
    align: "center",
    headerAlign: "center"
  }
];

export default function List(props) {
  const [pageSize, setPageSize] = React.useState(25);

  let result_search = [];
  if (props.search_address && props.search_address !== "") {
    rows.forEach(function (tree) {
      let string = tree.cchain.toLowerCase();
      if (string.startsWith(props.search_address.toLowerCase())) {
        result_search.push(tree);
      }
    });
  } else {
    result_search = rows;
  }
  return (
    <Container>
      <Box component="div" sx={{ width: "md", mb: "2rem" }}>
        <DataGrid
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
          disableColumnMenu={true}
          rows={result_search}
          columns={columns}
          showColumnRightBorder={false}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Address not found
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Result not found
              </Stack>
            )
          }}
        />
      </Box>
    </Container>
  );
}
