import React from "react";
import cover from "./cover.jpg";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function Intro(props) {
  return (
    <Container>
      <Box component="div" sx={{ mb: "2rem" }}>
        <img src={cover} className="cover" alt="cover" />
      </Box>
      <Box component="div" sx={{ mb: "2rem" }}>
        <p style={{ textAlign: "justify" }}>{props.text}</p>
      </Box>
    </Container>
  );
}
